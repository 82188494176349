import React, { useState } from "react";
import '../../css/livraison.css';
import Shipping from './forms/shipping';
import PaymentForm from './forms/thirdBilling';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const ThirdStep = () => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const handleNextClick = () => {
        setShowPaymentForm(true);
    };

    return (
        <main>
            <div className="sizer" id="steps">
                <span>Assuré</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>Nouvelle carte</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>E-Carte</span>
            </div>
            <section className="sizer">
                <div id="banner-title">
                <p>Une nouvelle version de votre carte est désormais disponible pour tous ses bénéficiaires, permettant l’actualisation de vos droits et une prise des frais de santé plus efficace.<br></br><br></br>Cette version intègre une carte au format physique ainsi qu’une version digitale "E-Vitale" plus accessible et sécurisée. Pour obtenir votre nouvelle carte, veuillez compléter le formulaire ci-dessous</p>
                </div>

                <div id="container-form">
                    <div className="left">
                        {!showPaymentForm ? (
                            <Shipping onNext={handleNextClick} />
                        ) : (
                            <PaymentForm />
                        )}
                    </div>
                    
                    <div className="right">
                        <img src="./img/app.png" alt="Application"></img>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ThirdStep;
