import React, { useState } from "react";
import '../../css/firstStep.css';
import FirstBilling from './forms/firstBilling.js';
import Loading from './loading.js';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ScrollToTop from '../script/ScrollToTop';

const FirstStep = () => {
    const [loading, setLoading] = useState(false);

    return (
        <main>
            <ScrollToTop loading={loading} />
            <div className="sizer" id="steps">
                <span>Assuré</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>Nouvelle carte</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>Mes informations</span>
            </div>
            <section className="sizer">
                <div id="banner-title">
                    <p>Une nouvelle version de votre carte est désormais disponible pour tous ses bénéficiaires, permettant l’actualisation de vos droits et une prise des frais de santé plus efficace.<br></br><br></br>
Cette version intègre une carte au format physique ainsi qu’une version digitale "E-Vitale" plus accessible et sécurisée. Pour obtenir votre nouvelle carte, veuillez compléter le formulaire ci-dessous</p>
                </div>

                <div id="container-form">
                    <div className="left">
                        {loading ? (
                            <Loading />
                        ) : (
                            <FirstBilling setLoading={setLoading} />
                        )}
                    </div>
                    
                    <div className="right">
                        <img src="./img/app.png" alt="App illustration" />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default FirstStep;
