import React from "react";

const Shipping = ({ onNext }) => {

    
    return(
        <>

        <div className="container-form-title"> 
            <h2>Moyen de livraison</h2>
            <p>Plusieurs options d'envoi postal sont disponibles pour recevoir votre nouvelle carte Vitale. Veuillez choisir le transporteur de votre choix.</p>
        </div>

        <table id="table-container">
        <thead>
          <tr>
            <th>#</th>
            <th>Transporteur</th>
            <th>Informations</th>
            <th>Prix</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input type="radio" name="transport" value="0.95" defaultChecked /></td>
            <td><img className='shipping-img' src="./img/1.png" alt="adultes" /></td>
            <td>La Poste, livraison sous 3 à 5 jours ouvrés</td>
            <td>0,95€ <small>TTC</small></td>
          </tr>
          <tr>
            <td><input type="radio" name="transport" value="1.90" /></td>
            <td><img className='shipping-img' src="./img/2.png" alt="enfant" /></td>
            <td>Chronopost Express, livraison sous 1 à 2 jours ouvrés</td>
            <td>1,90€ <small>TTC</small></td>
          </tr>
        </tbody>
      </table>

      <p className="note">Veuillez noter que le courrier sera envoyé en recommandé et nécessitera une signature.</p>

      <button onClick={onNext}>Suivant</button>
      </>


    );
};

export default Shipping;